.about {
  padding: 20px 30px;
  max-width: 1280px;
  margin: auto;

  .prototype-images {
    padding: 60px;
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    img {
      opacity: 0;
      animation: proto ease-in-out 1.5s forwards;
    }

    .cbcr:nth-child(1) {
      animation-delay: 0.5s;
    }

    .clarity:nth-child(2) {
      animation-delay: 1s;
    }

    .swave:nth-child(3) {
      animation-delay: 1.5s;
    }

    .windows:nth-child(4) {
      animation-delay: 2s;
    }

    .ws:nth-child(5) {
      animation-delay: 2.5s;
    }

    .wswap:nth-child(6) {
      animation-delay: 3s;
    }

    .clarityGlasses:nth-child(7) {
      animation-delay: 3.5s;
    }
  }

  @keyframes proto {
    to {
      opacity: 1;
    }
  }

  .stack {
    width: 350px;
    text-align: justify;
    padding-top: 15%;
    padding-left: 65%;
    line-height: 5px;
  }

  .content {
    width: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    &:last-child {
      padding-bottom: 40px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .about {
    max-width: 330px;

    .stack {
      padding-left: 0%;
    }

    .content,
    .experience,
    .education,
    .prototypes,
    .papers,
    .volunteering,
    .press {
      max-width: 335px;
    }
  }
}

@media only screen and (max-width: 850px) {
  .prototype-images {
    display: none !important;
  }
}
