.contact {
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  width: 367px;

  .title {
    font-weight: 600;
    font-size: 20px;
    padding-top: 40px;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  input,
  textarea {
    font-family: "Darker Grotesque";
    font-size: 16px;
    border: 1px solid black;
    border-radius: 6px;
  }

  input[type="text"] {
    padding: 7px 10px;
    width: 343px;
    // font-size: 16px;
  }

  input[type="email"] {
    padding: 7px 10px;
    width: 343px;
  }

  textarea {
    padding: 5px 10px;
    width: 343px;
    height: 300px;
  }

  .submit {
    padding: 10px;
    width: 345px;
    background-color: rgb(180, 255, 30);
    font-family: "Thunder";
    font-size: 16px;
    border: 1px solid black;
    border-radius: 6px;
    cursor: pointer;
    color: black !important;
    width: 100%;

    &:hover {
      background-color: rgb(169, 240, 27);
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact {
    padding-top: 0px;
    width: 322px !important;
    // margin-top: 170px;

    .contact-form {
      margin: unset;
      padding: 10px;
      padding-bottom: 100px;
    }

    input {
      width: 280px;
    }

    input[type="text"] {
      padding: 5px 10px;
      // width: 100%;
      // width: 280px;
    }

    input[type="email"] {
      padding: 5px 10px;
      // width: 280px;
    }

    textarea {
      padding: 5px 10px;
      width: 280px;
      height: 300px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact {
    max-width: 330px;
    padding-top: 10%;
    height: 700px;
    width: 330px;

    .contact-form {
      padding-bottom: 0px;
      max-width: 330px;
      padding: unset;

      input,
      textarea {
        max-width: 330px;
        width: 300px;
      }
    }
  }

  .title {
    padding-top: 5% !important;
    margin-top: 0%;
  }
}
