@font-face {
    font-family: "OTF";
    src: url("./assets/fonts/OTFGlusp.ttf");
    font-display: swap;
}

@font-face {
    font-family: "Thunder";
    src: url("./assets/fonts/Thunder-Bold.otf");
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("./assets/fonts/Roboto-Regular.ttf");
    font-display: swap;
}

@font-face {
    font-family: "Space Grotesque";
    src: url("./assets/fonts/SpaceGrotesk.ttf");
    font-display: swap;
}

@font-face {
    font-family: "Darker Grotesque";
    src: url("./assets/fonts/DarkerGrotesque.ttf");
    font-display: swap;
}


body {
    margin: 0 auto;
    width: 100%;
    font-family: "Darker Grotesque";
    font-size: 15px;
    scroll-behavior: smooth !important;
}

body::-webkit-scrollbar {
    display: none;
}

a {
    text-decoration: none;
    color: black;
}

.visit {

    &:hover {
        width: 38px;
        border-bottom: 1px solid black;
    }

    .arrow {
        margin-left: 3px;
    }
}
