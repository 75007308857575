.loading {
  position: fixed;
  top: 50%;
  left: 51.1%;
  transform: translate(-50%, -50%);
}

.projects-container {
  .title {
    font-family: "Darker Grotesque";
    font-weight: 600;
    font-size: 28px;
  }
}

.projects {
  padding-top: 50px;
  width: 850px;
  margin: 0 auto;
  height: 85vh;

  .table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 80px;
    margin-bottom: 20px;
  }

  .row {
    display: flex;
  }

  .column {
    flex: 50%;
  }

  .title-box {
    margin-top: 35px;
  }

  .image-container {
    width: 700px;
    height: 385px;
  }

  #banner-image {
    max-width: 660px;
    height: 385px;
    padding-top: 5%;
    position: relative;
    object-fit: scale-down;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .description {
    margin-top: -10px;
  }

  button {
    margin-top: 30px;
    border: 1px solid black;
    color: black;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }
}

.snp-pagination {
  text-align: center;
  position: absolute;
  bottom: 65px !important;
  z-index: 10 !important;
}

.swiper-pagination-bullet {
  height: 32px;
  background: transparent;
}

@media screen and (max-width: 1500px) {
  .projects {
    max-width: 700px;
    height: 88vh;
    width: 100%;
    margin: 0 auto;

    .table {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 1200px) {
  .projects {
    max-width: 600px;
    height: 85vh;
    width: 100%;
    margin: 0 auto;

    .table {
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 600px) {
  .projects {
    max-width: 330px;
    height: 70vh;
    width: 100%;
    margin: 0 auto;
    padding-top: 30%;

    .table {
      margin: 0 auto;
    }

    ul {
      padding-left: 0;
    }

    #banner-image {
      padding-top: 0%;
      max-width: 330px;
    }
    .image-container {
      max-width: 330px;
    }
  }
}
