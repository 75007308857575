.lab {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  gap: 3rem;
  padding-bottom: 60px;
  padding: 20px;
  max-width: 800px;

  .lab-list:first-child {
    padding-top: 60px;
  }
  .lab-list:last-child {
    padding-bottom: 40px;
  }

  .lab-list {
    .visit {
      width: 35px;
      svg {
        margin-left: 0;
      }
    }
  }

  li {
    list-style: none;
  }

  .title {
    text-decoration: underline;
  }

  a {
    text-decoration: none;
    color: black;
  }

  button {
    border: 1px solid black;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10.8px;
  }
}

@media only screen and (max-width: 600px) {
  .lab {
    max-width: 330px;
  }
  .lab-list {
    padding-left: 5%;
  }
}
