.header-container {
  position: fixed;
  z-index: 3;
  background: white;
  width: 100%;
  height: 40px;
  top: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 15px;

  .maryam {
    visibility: visible;
  }

  .mimo {
    visibility: hidden;
    font-style: italic;
    font-size: 11px;
  }

  .left-nav {
    font-weight: 600;

    &:hover {
      .maryam {
        visibility: visible;
      }

      .mimo {
        visibility: visible;
      }
    }
  }

  .middle-nav {
    margin-right: -20%;
    font-style: italic;
  }

  a {
    text-decoration: none;
    color: black;
    padding: 5px;
  }

  .right-nav {
    right: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    height: 15px;
  }

  .header-container {
    max-width: 500px;
  }

  .middle-nav {
    display: none;
  }

  .mimo {
    font-style: italic;
    font-size: 11px;
  }

  .left-nav {
    .mimo {
      pointer-events: none;
      display: none !important;
      visibility: hidden !important;
    }
  }
}
