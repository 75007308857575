.footer-container {
  position: fixed;
  z-index: 3;
  background: white;
  width: 100%;
  height: 40px;
  bottom: 0;
  background-color: rgb(180, 255, 30);
  border-top: 1px solid black;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;

  .left-nav {
    margin-top: 8px;

    a {
      text-decoration: none;
      color: black;
      margin-right: 10px;
    }
  }

  .right-nav {
    margin-top: -7px;
  }
}
