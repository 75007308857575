.index {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  width: 100%;
  transform: translate(-50%, -50%);

  .main {
    width: 500px;
    padding-top: 20%;
    padding-left: 3%;
    font-size: 16px;
    line-height: 5px;
    overflow: hidden;

    span {
      line-height: 20px;
    }
  }

  .index-title {
    font-family: "Thunder";
    font-size: 60px;
    text-align: center;
    position: absolute;
    top: 20px;
    right: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .index {
    height: 500px;
  }
  .main {
    display: flex;
    flex-direction: column;
    max-width: 338px;
    margin: 0 auto;
    padding-left: 0%;

    p {
      &:first-child {
        padding-top: 50px;
      }
    }
  }

  .index-title {
    position: absolute !important;
    margin-left: auto;
    margin-right: 0%;

    transform: translate(10%, 0%);
  }
}
